/* THEME */

.breadcrumbHeader {    
    font-size: 15pt;
}

#header1Container {
    border: 0;
}

#mainContentWrapper .container {
    background-color: #FFFFFF;
}

#header2Container, #header3Container {
    background: #FFFFFF;
}

.conferenceModule .footer2 {
    margin-top: 0;    
}

.conferenceModule #footer2Wrapper .container {
    background: none;
    color: #000000;
    border-left-color: transparent;
    border-right-color: transparent;
}

.sidebarScrollSpy .nav-item.active> a {
    border-left: 0.3125rem solid #999;
    font-size: 0.75rem;
    font-weight: 500;
    color: #999;
    background-color: #eeeeee;
}

.sidebarScrollSpy .nav-link {
    font-size: 0.75rem;
    font-weight: 500;
    color: #999;
}

.sidebarScrollSpy .nav-pills .nav-link.active {
    border-left: 0.3125rem solid #999;
    font-size: 0.75rem;
    font-weight: 500;
    color: #999;
    background-color: #eeeeee;
}

.packageSelectBaseWrapper .radio, .packageSelectBaseWrapper .checkbox {
    margin-top: 0;
    margin-bottom: 0;
}

.navbar-expand-md .navbar-nav .nav-link {
	padding: 0.625rem 0.9375rem
}

li.portalMenuDropDownContainer ul.dropdown-menu .active>.nav-link,
li.portalMenuDropDownContainer ul.dropdown-menu .active>.nav-link:hover {
  color: #ffffff;
}

li.portalMenuDropDownContainer ul.dropdown-menu .nav-link:hover {
  color: rgba(0,0,0,0.4);
}

/* Media query styles should be further subcategorized into SMACSS style organization*/
/* Extra small devices, up to 767px and BASE css (mobile view sets the base)*/
@media (max-width: 767px) {
    #header1Wrapper {
        height: 49;
    }
}

/* Small devices and larger (tablets, 768px and up) */
@media (min-width: 768px) {
    #header1ContentWrapper {
        margin: 0 -0.9375rem;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }
}

.table tr.row {
    margin-left: 0;
    margin-right: 0;
}

.labelSpanStyle {
    font-weight: bold;
}

#loginInfo .headerStudentLoginContainer .dropdown-menu {
  position: absolute; 
  transform: translate3d(0, 1.625rem, 0);
  top: 0;  
  will-change: transform;
}

label {
  font-weight: bold;
}

legend {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1.25rem;
}

.card-info {
  background-color: #fff;
  border: 0; 
  margin-bottom: 1rem;
}

.card {
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.05);
    box-shadow: 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.05)
}

.card-info {
    border-color: #bce8f1;
}

.card-info>.card-header {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.card-title {
  margin-bottom: 0;
}

.popover {
    font-size: 0.875rem;
}

.popover-header {
    margin: 0;
}

#browseOfferingsContainer .card-info {
    border-color: #dddddd;
}

#footerNav #backToTopContainer .backToTop {
    background-color: #555555; 
    margin-top: -5px; 
}

.nav-link {
    position:relative;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
    background-color: #eeeeee;
}

.navbar-light {
    background-color:#eeeeee;
    border-color:#dddddd;
}

.navbar-light.bg-light {
    background-color:#eeeeee;
    border-color:#dddddd;
}

.navbar-light.bg-light .navbar-collapse,
.navbar-light.bg-light .navbar-form {
    border-color:#dddddd;
}

.navbar-light .navbar-nav .nav-link {
    color:#777777;
}

.navbar-light.bg-light .navbar-toggler:hover,
.navbar-light.bg-light .navbar-toggler:focus {
    background-color:#dddddd;
}

.radio,.checkbox {
    display: block;
}

.radio label,
.checkbox label {
    font-weight: normal;
}

.card-header {
    color:#333333;
    background-color:#f5f5f5;
    border-color:#dddddd;
}

.card-header + .panel-collapse > .card-body {
    border-top-color:#dddddd;
}

.panel-group .card,
.userForm .card,
.instructorForm .card,
.sectionHeader.card {
    border-radius: 0.5rem;
}

.panel-group .card + .card {
    margin-top:0.3125rem;
}

.panel-group .card-header {
    border-bottom:0;
}

.panel-group .card-header + .panel-collapse > .card-body,
.panel-group .card-header + .panel-collapse > .list-group-flush {
    border-top:1px solid #dddddd;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top:1px solid #dddddd;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border:1px solid #dddddd;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
    border-top: 0
}

@media screen and (max-width: 767px) {
    .table-responsive {
        border: 1px solid #dddddd
    }
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline:0.3125rem auto -webkit-focus-ring-color;
    outline-offset:-0.125rem;
}

.form-control-plaintext {
    padding-top:0.5625rem;
    padding-bottom:0.5625rem;
    margin-bottom:0;
    min-height:2.125rem;
}

.btn-link.disabled,
.btn-link:disabled {
    font-weight: 400;
    color: #3399F3;
    background-color: transparent;
    pointer-events: unset;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
    color: #999999;
    text-decoration: none;
}

.navbar-light.bg-light .btn-link {
    color:#777777;
}

.navbar-light.bg-light .btn-link:hover,
.navbar-light.bg-light .btn-link:focus {
    color:#3399f3;
}

.navbar-light.bg-light .btn-link[disabled]:hover,
fieldset[disabled] .navbar-light.bg-light .btn-link:hover,
.navbar-light.bg-light .btn-link[disabled]:focus,
fieldset[disabled] .navbar-light.bg-light .btn-link:focus {
    color:#444444;
}

.panel-default {
    border-color: #dddddd;
}

.panel-default > .card-header {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #dddddd;
}

.panel-default > .card-header + .panel-collapse > .card-body {
    border-top-color:#dddddd;
}

.panel-group .card + .card {
    margin-top:0.3125rem;
}

.panel-group .card-header + .panel-collapse > .card-body,
.panel-group .card-header + .panel-collapse > .list-group {
    border-top:1px solid #dddddd;
}

.table > thead > tr > th {
    vertical-align:bottom;
    border-bottom:2px solid #dddddd;
}

.form-horizontal .form-check-input {
    min-height:1.75rem;
    margin-top:0;
    margin-bottom:0;
    padding-top:0.5625rem;
}

.list-group + .card-footer {
    border-top-width:0;
}

.flex-column .nav-item + .nav-item {
    margin-top:0.125rem;
    margin-left:0;
}

.card > .card-body + .table,
.card > .card-body + .table-responsive,
.card > .table + .card-body,
.card > .table-responsive + .card-body {
    border-top:1px solid #dddddd;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-menu .subMenu > li > a:hover,
.dropdown-menu .subMenu > li > a:focus {
  text-decoration:none;
  color: #333333;
  background-color: #e4e4e4;
}

.badge-pill {
	background-color: #3399f3;
	color: #ffffff;
	font-size: 0.875em;
}

/* END of Destiny's customization of the Bootswatch's spacelab theme */
