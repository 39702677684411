h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a{
    font-family:$primary-font !important;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-weight:bold !important;
}

body{
    h1,.h1 {
        font-size: 2rem;
    }
    h2,.h2 {
        font-size: 1.6rem;
    }
    
    h3,.h3 {
        font-size: 1.45rem;
    }
    h4,.h4 {
        font-size: 1.35rem;
    }
    h5,.h5 {
        font-size: 1.25rem;
    }
    h6,.h6 {
        font-size: 1.15rem;
    }
}


.newsItemStory h1{
    font-size: 2rem;
}