$alt-color:#fefffb;
$middle-nav:1240px;
#header1 {
    width: 100%;
    margin: 0;
    padding: 0 10px;
}
#headerWrapper{
    border:1px solid #ccc;
}
#header0Wrapper,
#header1Wrapper{
	background-image:none;
    border:none;
    .col-lg-12{
        padding:0 10px;
    }
    .row{
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-right: 0;
        margin-left:0;
    }
	header{
        padding: 20px 0;
        max-width: 1180px;
        margin:0 auto;
        .align-center{
            align-items: center;            
            @media screen and (min-width: $middle-nav + 1) {
                display: flex !important;
                justify-content: center;
            }
        }
        .nav-container{
            @media screen and (max-width: 1500px) {
                justify-content: flex-end;
            }
            @media screen and (max-width: $middle-nav) {
                display: none;
                position: absolute;
                width: 100%;
                background-color: rgb(255, 255, 255);
                top: 82px;
                z-index: 500;
                padding: 30px;
                text-align: right;
                right: -24px;
                width: 370px; 
                border:1px solid #ccc;               
            }              
            nav{   
                li{
                    font-weight: 500;
                    list-style: none;
                    padding: 0 10px;
                }
                a {
                    text-decoration:none;
                    color:#000;
                    font-size: 16px;
                }
                @media screen and (min-width: $middle-nav + 1) {
                    display: flex;     
                }                
            }
        }
        .utility-nav{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            justify-items: flex-end;
            align-items: center; 
            i {
                font-size: 19px;
                margin-right: 13px;
            }           
            .headerStudentLoginDropdownLink{
                color:#ffffff !important;
            }
            span:before{
                color:#ffffff;
            }
            #cartInfo,
            #loginInfo{
                @include border-radius(5px);
                display: inline-block;
                margin-right: 10px;
            }
            #loginInfo {
                position: relative;
                #loginNameWrapper {
                    position: absolute;
                    width: 150px;
                    top: -30px;
                    right: 0;
                    color: #000;
                    font-size: 13px;
                    a {
                        color:#000;
                    }
                }    
                #logoutLinkWrapper:before {
                    display: none;
                }            
            }
            #loginNameWrapper,
            #logoutLinkWrapper{
                display: inline-block;
                width:auto;
                max-width: none;
            }
            #cartInfo{
                position: relative;
                margin-right: 30px;
                background:#000;
                padding: 6px 8px 0;
                @media screen and (max-width:650px){
                    margin-right: 20px;
                }
                .fa-shopping-cart:before, 
                .glyphicon.glyphicon-shopping-cart:before {
                    background: url("https://cpe.bu.edu//images/backpack-light.svg");
                    content: "";
                    width: 21px;
                    height: 19px;
                    background-position:center;
                    background-size:cover;
                    display: block;
                }
                #cartCount{
                    top: 10px;
                    right: -20px;       
                }        
            }
            #loginInfo{
                padding: 0 20px;
                background-color:$primary-color;
            }
        }     
        .menu-toggle {
            width: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 100000;
            display: block;
            margin-top: -10px;
            @media screen and (min-width:$middle-nav) {
                display: none;
            }
            @media screen and (max-width: $mobile) {
                right:15px;
            }
            .one,
            .two,
            .three {
                width: 100%;
                height: 5px;
                margin: 6px auto;
                backface-visibility: hidden;
                background: $primary-color;	
                -moz-transition-duration: 0.3s;
                -o-transition-duration: 0.3s;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;			
            }
            &.on {
                .one {
                    @include transform(rotate(45deg) translate(7px, 7px));
                }
                .two {
                    opacity: 0;
                }
                .three {
                    @include transform(rotate(-45deg) translate(8px, -9px));
                }
    
            }		
        }
	}
}
#header3Wrapper {
    display: none;
    .col-lg-12{
        padding:0;
        .mainNavBar{
            padding:0;
            background: #444;
            background-image: -webkit-linear-gradient(bottom, #444, #2c2c2c);
            background-image: linear-gradient(to bottom, #444, #2c2c2c);
            border:none;
            @include box-shadow(0px, 2px, 8px, rgba(0,0,0,.4));
            .navbar-nav{
                width: 100%;
                justify-content: space-around;
                display: flex;  
                padding: 0 20px;      
            }
            a{
                color:#ffffff;
                //border-left: 1px solid #000;
                //border-right: 1px solid #000;        
                &:hover,
                &:focus{
                    color:$accent1;
                    background-color:transparent;
                }
            }
            .nav-item {
                ul {
                    position: absolute;
                    background: $gray;
                    z-index: 5000;
                    list-style: none;
                    padding:10px;
                    display: none;
                    li{
                        width: 140px;
                        line-height: 22px;
                    }
                }
                &:hover ul {
                    display: block;
                }
            }
            @media screen and (max-width:768px){
                .navbar-toggler{
                    background-color:#ffffff;
                }
            }
            .portalMenuDropDownContainer{
                display: none !important;
            }    
        }        
    }    
}