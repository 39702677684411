//schedules and registration

// #pageContentManagementItemContentCM000014{
//     th:nth-of-type(2),
//     td:nth-of-type(2) {
//         width: 70%;
//     }
//     th:last-of-type,
//     td:last-of-type{
//         width: 190px;
//     }
// }
//registration
#pageContentManagementItemContentCM000014{
    h3{
        margin-top:20px;
    }
    table{
        margin-bottom:20px;
    }
    th,
    td {
        width: 180px;
    }    
    th:nth-of-type(2),
    td:nth-of-type(2) {
        width: 50%;
    }    
}