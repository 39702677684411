footer{
	background: $primary-color;
	#footer1Container{
		padding:30px;
		position:relative;
	}
	.col-lg-12 {
		display: flex;
		justify-content: space-between;
		@media screen and (max-width:$tablet) {
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
	ul{ 
		display: inline-block;
		list-style: none;
		float:left;
		display: inline-block;
		padding:0;
		margin-bottom: 0;
		width: 100%;
		li{
			padding:0 14px;
			@media screen and (min-width:$tablet - 1) {
				display: inline-block;
			}			
			a{
				color:$light-blue;
				&:hover {
					color:#fff;
					text-decoration: none;
				}
			}
		}
	}
	.boston-logo{
	    width: 110px;
		+ i {
			display: none;
		}
	}
	.footer-nav {
		i {
			font-size: 16px;
			font-weight: 400;
		}
		li:first-of-type {
			padding-left:0;
		}
	}
	i{
		color:$primary-color;
		color: #ffffff;
		font-size: 28px;
		&:hover{
			//color:$accent1;
		}
	}
	.destiny-one{
    	right: 30px;
    	width: 50px;
	}
	.logo{
		width: 100%;
		span {
			color:#fff;
		}
	}
	small{
		color:$light-blue;
		font-size: 14px;
		font-family: $primary-font;
	}
	.college-logo{
		font-family: $primary-font;
		font-size: 16px;
		color:#fff;
		.bold{
			font-weight: bold;
		}
	}
	.footer-left {
		display: flex;
		flex-direction: column;
		* > {
			width: 100%;
		}
	}
	.footer-right { 
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		@media screen and (max-width:$tablet){
			flex-direction: row;
			align-items: flex-end;
			width: 100%;
			justify-content: space-around;
		}
	}
	.footer-left,
	.footer-right {
		> * {
			margin-bottom:15px;
			display: inline-block;
		}
	}
	.footer-cta{	
		h2{
			margin:0;
			color:#ffffff;
			font-size: 20px;
			padding-bottom:10px;
		}
		[class*="btn-"]{
			color:$primary-color !important;
			padding: 8px 10px;
			background-color: #fff !important;
			@media screen and (max-width:500px){
				float:none;
			}
		}		
	}
}