///Break Points
$grid: 1400px;
$wide: 1200px;
$break: 1024px;
$wrappernarrow:900px;
$tablet: 768px;
$handheld: 600px;
$mobile: 400px;
$iphone5: 320px;

///Fonts
$primary-font: benton-sans, sans-serif;

///Colors
$primary-color:#153F57; //updated blue
$secondary-color:#AFB032; //updated blue

$gray:#333333;
$accent1:#435713; // yellow
$light-gray:#C4C4C4;

$light-blue:#CBDAF6; //updated

$gradient:linear-gradient(to top,transparent 20px,rgba(203,218,246,1) 20px,rgba(175,176,50,0) 453px);

///Animation 
$duration:500ms;
$duration-long:1000ms;