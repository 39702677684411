.newsItemStory{
	position: relative;
	@media screen and (min-width:981px){
		img{
			padding: 0 0 30px 0;
		}
	}
	h1{
		position: absolute;
		font-size:1.9rem;
		color: #fff;
		margin: -133px 0 0;
		padding:.5em 1.25em .5em 1.24em;
		background-color: rgba(51, 51, 51, 0.8);
		@include box-sizing(border-box);
		@media screen and (max-width:980px){
			position: relative;
			margin:0 0 30px 0;
			padding:.5em .5em .5em .5em;
		}
	}
}