#pagePublicHome {
	.home-programs{
		ul{
			list-style: none;
			padding-left:0;
			a{
				font-size: 20px;
				color:$gray;
			}
		}
	}
	#cartInfo{
		display: none !important;
	}	
}