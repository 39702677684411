@import 'scss/master/destiny';

@import 'scss/master/variables';
@import 'scss/master/mixins';

@import 'scss/master/typo';
@import 'scss/master/global';
@import 'scss/master/article';

@import 'scss/modules/header';
@import 'scss/modules/newsItemStory';
@import 'scss/modules/pagePublicHome';
@import 'scss/modules/footer';
@import 'scss/modules/pagination';
@import 'scss/modules/programAreas.scss';
@import 'scss/modules/reg-sch.scss';

@import 'scss/modules/student-portal.scss';

@import 'scss/modules/evergreen';

@import 'scss/elements/btn';
@import 'scss/elements/form';