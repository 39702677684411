/// https://github.com/matthieua/sass-css3-mixins/blob/master/css3-mixins.scss

/// Prefix
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}


/// Mixins
@mixin animation($str) {
  @include css3-prefix('animation', $str);
}

@mixin backface($value: hidden) {
  backface-visibility: $value;
  -webkit-backface-visibility: $value;
  -moz-backface-visibility: $value;
  -ms-backface-visibility: $value;
  -o-backface-visibility: $value;
}

@mixin background-opacity($color: #000, $opacity: 0.85) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin background($size: cover, $position: center) {
  background-size:$size;
  background-position:$position; 
  background-repeat:no-repeat;
}

@mixin border-radius($radius: 5px) {
    @include css3-prefix('border-radius', $radius);
}

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
    @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}

@mixin box-sizing($type: border-box) {
  @include css3-prefix('box-sizing', $type);
}

@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin filter($filter-type,$filter-amount) { 
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}


@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
  font-family: $fontFamily;
  src: url($eotFileSrc)  format('eot'),
       url($woffFileSrc) format('woff'),
       url($ttfFileSrc)  format('truetype'),
       url($svgFileSrc + $svgFontID) format('svg');
}

@mixin opacity($opacity: 0.5) {
    $opacityMultiplied: ($opacity * 100);
    filter:         alpha(opacity=$opacityMultiplied);
    -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
    @include css3-prefix('opacity', $opacity);
}

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
    text-shadow: $x $y $blur $color;
}

@mixin transform($params) {
  @include css3-prefix('transform', $params);
}

@mixin transform-origin($params) {
  @include css3-prefix('transform-origin', $params);
}

@mixin transform-style($style: preserve-3d) {
  @include css3-prefix('transform-style', $style);
}

@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  }

  @else {
    @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}