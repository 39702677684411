a,
button{
    &.btn-secondary{
    	background-color: #ffffff;
    	background-image: none;
		border:1px solid #666;
		color:#666;
    }
	&.btn-primary{
		font-weight: bold !important;
	    background: $primary-color !important;
	    @include border-radius(7px !important);
	    cursor: pointer !important;
		border-color:$primary-color !important;
		color:#ffffff;
	}    
}