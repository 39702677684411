.evergreen{
	$evergreen:#355e3b;
	#mainContentWrapper{
		a[href*="http://www.bu.edu/evergreen"]{
			border:1px solid $evergreen;
		}	
		a{
			color:$evergreen;
			font-weight:bold;
		}
		.evergreen-nav{
			background:#355e3b;
			ul{
				list-style: none;
				padding:0 15px;
				li{
					padding:0 6px;
					display: inline-block;
				}
				a{
					padding:6px 8px;
					color:#ffffff;
					display: block;
				}			
			}
		}
	}
}