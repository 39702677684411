.variable-content,
#programAreaDescription {
	img{
		height:auto;
	}	
	@media screen and (min-width:450px){
		img[style*="float: right"],
		img[align*="right"]{
			padding:0 0 20px 30px;
		}
	}
	@media screen and (max-width:450px){
		img:not(.preserve) {
			clear:both;
			float: left;
			padding:30px 0;
			width: 100% !important;
			height:auto !important;
		}
	}
	iframe{
		max-width: 100%;
	}
}