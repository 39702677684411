::-moz-selection {
    color: #fff;
    background: #435713;
}
::selection {
    color: #fff;
    background: #435713;
}

body{
	padding:0 !important;
	a{
		color:$primary-color;
		&:hover {
			color:#000;
		}
	}
}

#mainContentContainer {
    position: relative;
	padding:0;
    .sectionHeader{
		h2 {
			font-size: 24px;
			margin:0;
			color:#fff;
		}
	}
}

body:not(#hero-full-width) #mainContent #contentModule1 a,
#courseProfile a {
	color:#738921;
	text-decoration: underline; 
}

.courseProfileSectionAvailability_available, 
.programOfferingAvailability_available{
	color:#738921;
}

img{
	max-width: 100%;
}
#searchBreadcrumb{
	padding:0 0 40px;
}

.btn-group button:hover .glyphicon{
	color:$primary-color;
}

a[href*="professional.bu"]{
	border:1px solid red;
}

.fa-external-link-alt{
	margin: 0 0 0 4px;
}

.top-header{
	color:$primary-color;
	background: #fff;
    //position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    font-size: 16px;
	z-index: 500;
	@include box-shadow(1px ,-1px, 6px, 4px, rgba(0,0,0,.4));
	display: inline-block;
    z-index: 500000;	
	.wrapper{
		max-width: 80%;
		margin: 12px auto;
		display: inline-block;
	}
	.red{
		background-color:$primary-color;
		color:#ffffff;
		h3,p{
			display: inline-block;
		}
	}
	h3{
		padding: 15px 20px; 
		font-size: 17px;
		margin:0;
	}
	p{
		margin-bottom: 0;
	}
}

#buttonViewInstructors{
	display: none;
}

#hero-full-width #mainContentContainer {
    max-width:100%;  
	margin:0;
	padding:0;
}
#mainContentContainer {
	margin:60px auto;
	@media screen and (max-width:$tablet) {
		margin:40px auto;
		padding:0 30px;
	}
}

.wrapper {
	max-width:1110px;
	margin:0 auto;
	padding:0 30px;
}

.btn-1{
	background-color: $accent1;
    color: #fff !important;
    text-decoration: none !important;
	padding:10px 15px;
	@include border-radius(24px);
	font-weight: bold;
	&:hover{
		color:#ccc;
		text-decoration: none;
	}
}

.btn-2{
	background-color: #fff;
	color:$accent1;
	padding:10px 15px;
	@include border-radius(24px);
	font-weight: bold;
	&:hover{
		color:#000;
		text-decoration: none;
	}
}

.hero {
	padding:10% 0;
	background-position: center;
	background-size: cover;
	position: relative;
	&:before{
		content:'';
		position: absolute;
		top:0;
		left:0;
		height:100%;
		width: 100%;
		background: rgba(0,0,0,.3);
	}
	.hero-content{
		position: relative;
		z-index: 15;
		h1 {
			color:#fff;
			margin-bottom: 30px;
			font-size: 3em;
		}
	}
}

.programs {
	padding: 40px 0;
	background: linear-gradient(0deg, $primary-color 16.31%, $accent1 97.12%);
	.program-intro{
		text-align: center;
		padding:20px 0;
		max-width: 386px;
		margin:0 auto;
		h2{
			font-size: 46px;
			color:#fff;
		}
		p{
			color:#fff;
		}
	}
	.programs-grid{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.card {
			width: 100%;
			margin:20px;
			margin-bottom:20px;
			@media screen and (min-width:$tablet) {
				width: 31%;
				margin: 0 1.15% 20px;
			}
			.image-container{
				height: auto;
				padding-bottom: 100%;
				position: relative;
			}
			img{
				width: 100%;
				position: absolute;
				top:0;
				left:0;
				height:100%;
				object-fit: cover;
			}
			h5 {
				margin-bottom: 0;
				padding:15px;
			}
			a{
				color:$primary-color;
				text-decoration: none !important;
			}
		}
	}
}

.panel {
	.wrapper{
		display: flex;
		flex-direction: column;
		@media screen and (min-width:$tablet) {
			flex-direction: row;
			min-height: 400px;
			[class*="container"]{
				width:50%; 
			}
		}
	}
	.container-text{
		display: flex;
		align-items: center;
	}
	.container-image {
		justify-content: stretch;
    	display: flex;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
	.contain-left,
	.contain-right{
		max-width: 660px;
		padding: 30px 10%;
	}
	&.right-panel .container-text {
		justify-content: flex-end;
	}
	&.left-panel .container-text {
		justify-content: flex-start;
	}	
}

.logo{
	span {
		font-family: $primary-font;
		font-size: 16px;
		color:#000;
		&.bold{
			font-weight: bold;
		}
	}
	p{
		display: inline-block;
		color:$gray;
		padding-left: 20px;
		font-size: 20px;
		@media screen and (max-width:768px) {
			font-size: 12px;
			padding-left: 10px;
		}
		@media screen and (max-width:550px) {
			padding:10px 0 0;
		}
	}
	&:hover{
		text-decoration: none;
	}
}

#courseProfile{
	h2{
		margin-top:20px;
	}
	a{
		font-weight:bold;
	}
}

#courseProfileSections{
	.sectionHeader{
		@include border-radius(0);
		border:1px solid $light-gray;
	}
	.card-header{
		border-top:3px solid $primary-color;
	}
	.card{
		@include border-radius(0);
		border:1px solid $light-gray;
	}
	.card-info > .card-header{
		background-color: transparent;
		color:#000;
	}
	.panel-default {
		border-radius: 0;
		@include border-radius(0);
	}
}

#variableContentBlockCM000016{
	h1{
		text-align: center;
		+ img {
			display: none;
			+ p,
			+ p + p,
			+ p + p + p {
				text-align: center;
			}
		}		
	} 
}