.pagination{
	a{
		color:#000;
		&:hover{
			color:$primary-color;	
		}
	}
	.active a.page-link {
		color:#fff;		
		background-color:$primary-color;	
		border:$primary-color;	
	}
}