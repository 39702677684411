#pageProgramAreasList .programs{
    background: transparent;
}
.program-header{
    text-align: center;
    padding: 10% 30px;
    background-color: $primary-color;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    h1{
        color:#fff;
        @media screen and (min-width:$break){
            font-size:3em;
        }
    }
}
#programAreasAccordion{
    .card-header{
        a{
            color:$gray;
            font-weight:bold;
        }
        .glyphicon{
            color:$gray;
            margin-top: 2px;
        }
    }
    .card-info{
        border-color:#000;
        .collapsed{
            background-color:#000;
        }
        .programArea a {
            color:#ffffff;
        }
        .glyphicon{
            color:#ffffff;
        }             
    }
    .programStream a {
        font-weight:bold;
        font-size:15px;
        color:#000;
    }       
}